<template>
  <div class="row">
    <OutstateOrganizationForm :id-organization="idOrganization" :header-text="'Editing Educational Organization:'"
                              :editing-existing="true" :submit-text="'Submit Changes'"/>
  </div>

</template>

<script>
import OutstateOrganizationForm
  from "@/views/SuperUserViews/EducationalOrganizationManagement/OutstateOrganization/OutstateOrganizationForm";

export default {
  name: "OutstateOrganizationEdit",
  components: {OutstateOrganizationForm},
  props: {
    idOrganization: Number
  },
  setup(props, context) {

  }
}
</script>

<style scoped>

</style>